import React from "react";
import { Link } from "gatsby";
import "./button.css";

function Button(props){
    if(props.color == "blue"){
        return (
            <button className="btn_wrapper">
                <Link to={props.href} className="btn_border border_blue">
                    <span className="btn_text bg_blue">{props.text}</span>
                </Link>
            </button>
        );
    }
    else if (props.color == "orange"){
        return (
            <button className="btn_wrapper">
                <Link to={props.href} className="btn_border border_orange" >
                    <span className="btn_text bg_orange">{props.text}</span>
                </Link>
            </button>
        );
    }
    else if (props.color == "white"){
        return (
            <button className="btn_wrapper">
                <Link to={props.href} className="btn_border border_white" >
                    <span className="btn_text bg_white">{props.text}</span>
                </Link>
            </button>
        );
    }
    else {
        return (
            <button className="btn_wrapper">
                <Link to={props.href} className="btn_border border_orange" >
                    <span className="btn_text bg_orange">{props.text}</span>
                </Link>
            </button>
        );
    }
}
export default Button;