import React from "react";
import Button from "../button/button";

function ContactSection() {
    return(
        <section>
            <div className="w-screen bg-contact bg-bottom bg-no-repeat bg-cover">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="w-full py-20 lg:w-7/12 2xl:w-5/12 mx-auto flex flex-col items-center justify-center">
                        <h2 className="text-3xl lg:text-4xl font-semibold text-black-excendo text-center">Kontakt</h2>
                        <p className="text-center text-gray-excendo  mt-4 lg:mt-8 xl:mt-10">Vill du veta mer om hur vi arbetar eller vill du komma i kontak med oss? Fyll i vårt kontaktformulär eller ring oss och låt vår resa tillsammans börja!</p>
                        <div className="w-full mt-14 flex flex-col md:flex-row items-center justify-evenly pr-4">
                        <span>
                            <Button
                            color="orange"
                            href="/kontakt"
                            text="Kontakta Oss"
                            />
                        </span>
                        <span className="mt-10 md:mt-0">
                            <Button
                            color="white"
                            href="/kontakt"
                            text="+46(0) 8 - 550 350 55"
                            />
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default ContactSection;